import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontFamilies,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/solidgear/helpers';

import { HTag } from '../HTag/HTag';

export const DualProductBlockStyled = styled.div<{ $fullWidth?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.solidBlack};
  aspect-ratio: 1 / 1;
  margin-inline: auto;

  ${({ $fullWidth }) =>
    !$fullWidth
    && css`
      max-width: 1440px;
    `}

  @media screen and (min-width: ${breakpoints.desktop}) {
    aspect-ratio: 2 / 1;
  }
`;

export const Outer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  z-index: 1;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    column-gap: 20px;
  }
`;

export const Inner = styled.div`
  grid-column: span 4;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    grid-column: 4 / span 6;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    grid-column: 5 / span 4;
  }
`;

export const ContentWrapper = styled.div`
  padding-inline: ${spacing.regular};
  padding-bottom: ${spacing.regular};
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin: 0;
    padding: 0;
    margin-bottom: ${spacing.regular};
    font-family: ${fontFamilies.fontHero};
    font-style: normal;
    font-weight: normal;
    font-size: ${fontSizes.header2};
    line-height: ${lineHeights.header2};
    color: ${colors.white};
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-shadow: 0 4px 30px rgb(0 0 0 / 25%);

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      font-size: ${fontSizes.header1};
      line-height: ${lineHeights.header1};
    }
  }

  a {
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }
`;

export const Dash = styled.span`
  width: 24px;
  border: 1px solid ${colors.white};
  margin: ${spacing.xsmall} 0;
`;

export const Label = styled.p`
  padding: 0;
  margin: 0;
  font-family: ${fontFamilies.fontRegular};
  font-style: normal;
  font-weight: 'normal';
  font-size: ${fontSizes.header3Content};
  text-align: 'initial';
  color: ${colors.white};
`;

export const Title = styled(HTag)`
  margin: 0;
  padding: 0;
  margin-bottom: ${spacing.regular};
  font-family: ${fontFamilies.fontHero};
  font-style: normal;
  font-weight: normal;
  font-size: ${fontSizes.header2};
  line-height: ${lineHeights.header2};
  color: ${colors.white};
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: 0 4px 30px rgb(0 0 0 / 25%);

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    font-size: ${fontSizes.header1};
    line-height: ${lineHeights.header1};
  }
`;

export const ImagesContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (min-width: ${breakpoints.desktop}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ImageWrapper = styled.div`
  position: absolute;
  aspect-ratio: 1 / 1;
  top: 0;
  bottom: 0;

  &:first-child {
    grid-column: span 2;
  }

  &:last-child {
    display: none;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    &:first-child {
      grid-column: 1;
    }

    &:last-child {
      display: block;
      grid-column: 2;
    }
  }
`;
